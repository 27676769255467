<template>
  <div
    v-if="identity.number_of_activities > 1"
    class="dark:bg-transparent dark:hover:bg-zinc-800 rounded-xl border-gray-300 shadow-lg"
  >
    <NuxtLinkLocale :to="identity.link || identity.url">
      <div class="w-[210px] h-[280px] md:w-[260px] md:h-[290px] overflow-hidden rounded">
        <div class="p-0">
          <SmartImg
            :loading="lazyLoadImg"
            class="w-full h-36 object-cover rounded"
            :src="identity.featured_image"
            sizes="(max-width: 767px) 210px, 260px"
          ></SmartImg>
        </div>
        <div class="px-2 md:p-2">
          <h3
            class="text-md md:text-xl py-2 font-semibold text-zinc-900 dark:text-white whitespace-normal break-words line-clamp-2 md:line-clamp-none h-16 md:h-16 leading-5"
          >
            {{ identity.label || identity.name_alias }}
          </h3>
          <div v-if="identity.reviews_count" class="flex items-center mt-2">
            <StarRating
              :reviews_count="identity.reviews_count"
              :filled_stars="filledStars"
              :has_half_star="hasHalfStar"
              :rating="identity.reviews_average"
            />
          </div>
          <span class="text-md text-zinc-400 dark:text-white">
            {{ localizedNumber(identity.number_of_activities, 'decimal') }} {{ $t('Activities') }}
          </span>
        </div>
      </div>
    </NuxtLinkLocale>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import SmartImg from '~/components/SmartImg.vue';

const props = defineProps({
  identity: {
    type: Object,
    required: true,
  },
  lazyLoadImg: {
    type: Boolean,
    default: true,
  },
});

const filledStars = computed(() => Math.floor(props.identity.reviews_average));
const hasHalfStar = computed(() => props.identity.reviews_average - filledStars.value >= 0.5);
</script>

<style>
.material-icons {
  font-family: 'Material Icons';
  font-size: 24px;
  line-height: 1;
  vertical-align: middle;
}
</style>
