<template>
  <NuxtLinkLocale :to="poster.link" prefetch
    ><div class="rounded overflow-hidden shadow-lg relative h-full">
      <SmartImg
        class="w-[150px] h-[200px] md:w-[200px] md:h-[250px] object-cover"
        :src="poster.featured_image"
        :alt="poster['label']"
        sizes="(max-width: 767px) 150px, 200px"
      ></SmartImg>
      <div
        class="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent text-white text-center py-2"
      >
        <h3 class="text-bold text-4xl">
          {{ poster.label ?? poster.name_alias ?? poster.name }}
        </h3>
      </div>
    </div></NuxtLinkLocale
  >
</template>

<script>
export default {
  name: 'CityPoster',
  props: {
    poster: {
      type: Object,
      required: true,
    },
  },
};
</script>

<script setup>
import SmartImg from './SmartImg.vue';
</script>

<style scoped>
/* Ensure the image covers the entire div and the height is smaller */
.max-w-sm {
  max-width: 10rem;
}

.bg-gradient-to-t {
  background: linear-gradient(to top, black, transparent);
}
</style>
